<template>
  <PageTemplate>
    <template slot="nav">
      <BasicNav>
        <template slot="nav-center">
          <div class="flex justify-center">
            <h1 class="text-3xl">{{ title }}</h1>
          </div>
        </template>
      </BasicNav>
    </template>
    <template slot="body">
      <KioskLogs
          :columns="columns"
          :current-page="currentPage"
          :total-rows="totalRows"
          :logs="logData"
          :loading="loadingLogs"
          :fetch-error="fetchError"
          no-log-message="No Dispense Logs Found"
          @pageChange="updateDispenseParams($event)"
          @detailsClick="goToLogDetail"
      />
    </template>
  </PageTemplate>
</template>
<script>
import KioskLogs from '../../components/KioskLogs'
import {mapActions, mapGetters, mapMutations} from "vuex";
import PageTemplate from "../../components/Helpers/Pages/PageTemplate";
import BasicNav from "../../components/Helpers/Navs/BasicNav";
import LocalLogFormatters from "../../mixin/LocalLogFormatters";

const RESET = "RESET";
const REJECT_RESET = "REJECT_RESET";

export default {
  name: "DispenserLogs",
  mixins: [LocalLogFormatters],
  components: {KioskLogs, PageTemplate, BasicNav},
  data() {
    return {
      title: "Dispenser Event Logs",
      noLogMessage: "No Dispenser Logs to show",
      loadingLogs: true,
      fetchError: false,
      columns: [
        {
          label: 'Event',
          field: 'event',
          sortable: false,
          formatFn: this.formatLogType,
          type: String,
        },
        {
          label: 'Status',
          field: 'status',
          formatFn: this.capitalizeWords,
          type: 'String',
          sortable: false,
        },
        {
          label: 'User',
          field: 'user',
          formatFn: this.formatLogUser,
          sortable: false,
          type: 'String',
        },
        {
          label: 'Date/Time',
          field: 'dateTime',
          formatFn: this.formatLogDate,
          sortable: false,
          type: 'String',
        },
        {
          label: 'Details',
          field: 'details',
        },
      ],
    };
  },
  computed: {
    ...mapGetters("DispenseLogs", ["currentPage", "totalRows", "logs"]),
    logData() {
      return this.logs;
    },
  },
  mounted() {
    this.getDispenseLogs();
  },
  methods: {
    ...mapActions("DispenseLogs", ["fetchLogs"]),
    ...mapMutations("DispenseLogs", ["updateParams"]),
    async getDispenseLogs() {
      this.loadingLogs = true;
      try {
        await this.fetchLogs();
      } catch {
        this.fetchError = true;
      }
      this.loadingLogs = false;
    },
    updateDispenseParams(params) {
      //vue-good-table start with page one we start with page 0 - so we would always be n-1
      this.updateParams({page: params.currentPage - 1});
      this.getDispenseLogs();
    },
    goToLogDetail(row) {
      switch (row.event) {
        case RESET:
          this.$router.push({
            name: "DispenserLogResetDetail",
            params: {id: row.id}
          })
          break;
        case REJECT_RESET:
          this.$router.push({
            name: "DispenserLogRejectDetail",
            params: {id: row.id}
          })
          break;
        default:
          this.$router.push({
            name: "DispenserLogDetail",
            params: {id: row.id}
          });
      }
    }
  },
}
</script>